import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import filicon from "../../../images/filter-new.png";
import SearchIcon from "@mui/icons-material/Search";
import addicon from "../../../images/addicon.png";
import { useDispatch } from "react-redux";
import oval from "../../../images/Oval-white.png";
import deleteIcon from "../../../images/deleteicon.png";
import AddNewLicense from "../../organizationadd/AddNewLicense";
import {
  DELETE_LICENSE_URL,
  GET_LICENCE_LIST_URL,
  HIDE_LICENSE_URL,
  LICENSE_LIST_FROM_PAX,
  SYSTEM_LICENSE_LIST_URL,
} from "../../common/ApiUrl";
import { CustomMessage, authHeader } from "../../common/mainfunctions";
import API from "../../common/API";
import { useSnackbar } from "notistack";
import OrgLicenseFilter from "../Filters/OrgLicenseFilter";
import CustomLoader from "../../common/CustomLoader";
import clrfilter from "../../../images/clearfilter.svg";
const initialValues = {
  licenseName: "",
  price: "",
  priceType: "",
};
export default function OrganizationLicenses(props) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState();
  const { data = [], getOrgDetails, orgid } = props;
  const { orgLicenseDetails = [] } = data || [];
  const [orgLicData, setLicenseData] = useState();
  const [licensData, setLicenseData1] = React.useState();
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPAge] = useState();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [openfil, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const openFilter = () => {
    setSearch("");
    setOpenFilter(!openfil);
  };

  const addlicense = () => {
    setOpen(!open);
  };
  const getSystemLicenceList = async () => {
    setLoading(true);
    const { licenseName, price, priceType } = filter;
    const options = {
      method: "GET",
      headers: authHeader(),
      url: SYSTEM_LICENSE_LIST_URL,
      params: {
        organizationId: orgid,
        search: search,
        nameFilter: licenseName ? licenseName : null,
        priceFilter: price ? price : null,
        priceTypeFilter: priceType ? priceType : null,
      },
    };
    try {
      const response = await API(options);
      if (response.status === 200) {
        const { status, data } = response.data;

        setLicenseData(data.list);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
      }
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    getOrgDetails();
    getSystemLicenceList(orgid, search);
  };

  useEffect(() => {
    getSystemLicenceList();
  }, [search, filter]);
  const getmicrosoftLicenceList = async () => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: LICENSE_LIST_FROM_PAX,
    };
    try {
      const response = await API(options);
      if (response.status === 200) {
        const { status, data } = response.data;
        const { number, totalPages } = data.subscriptionDetails.page;
        const { licenseDetails } = data.subscriptionDetails.content;
        console.log("license details", licenseDetails);
        console.log("resp data", data.subscriptionDetails.content);
        console.log("resp data page", data.subscriptionDetails.page);
        setLicenseData1(data.subscriptionDetails.content);
        setPage(number + 1);
        setTotalPAge(totalPages);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        const { message } = data;
        // CustomMessage(message, "error", enqueueSnackbar);
      }
    }
  };
  React.useEffect(() => {
    getmicrosoftLicenceList();
  }, []);
  useEffect(() => {
    if (page < totalPage) {
      getmicrosoftLicenceList();
    }
  }, [page, totalPage]);
  const hideLicense = async (e) => {
    const id = e.currentTarget.dataset.id;
    const flag = e.currentTarget.dataset.flag;
    const config = {
      method: "POST",
      url: HIDE_LICENSE_URL,
      headers: authHeader(),
      data: {
        licenseId: id,
        flag: flag,
      },
    };
    try {
      const response = await API(config);
      const { status, message } = response.data;
      if (status == "Success") {
        CustomMessage(
          "License Status Changed Successfully",
          "success",
          enqueueSnackbar
        );
        getOrgDetails();
        getSystemLicenceList(orgid, search);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (e) => {
    const id = e.currentTarget.dataset.id;
    const config = {
      method: "DELETE",
      url: DELETE_LICENSE_URL,
      headers: authHeader(),
      data: {
        licenseId: id,
      },
    };
    try {
      const response = await API(config);
      const { status, message } = response.data;
      if (status == "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
        getSystemLicenceList(orgid, search);

        getOrgDetails();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const setSearchFun = (e) => {
    setSearch(e.target.value);
  };
  const setSearchFuncall = () => {
    getSystemLicenceList(orgid, search);
  };
  useEffect(() => {
    if (orgid) {
      getSystemLicenceList(orgid, search);
    }
  }, [search]);
  const clearFilter = () => {
    setFilter(initialValues);
  };
  const isAnyFilterApplied = Object.values(filter).some(
    (value) => value !== ""
  );
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Grid
            sx={{ mt: 1.5, pl: 2 }}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <Typography className="license-head">
                License List
            <Tooltip title="Filter">
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={openFilter}
                >
                  <img src={filicon} />
                </IconButton>
                </Tooltip>
                {isAnyFilterApplied && (
            <Tooltip title="Clear filter">

                  <IconButton
                    aria-label="delete"
                    sx={{ padding: "10px" }}
                    size="small"
                    onClick={clearFilter}
                  >
                    <img src={clrfilter} />
                  </IconButton>
                  </Tooltip>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Paper
                  sx={{
                    p: "2px 6px",
                    display: "flex",
                    alignItems: "center",
                    width: "-webkit-fill-available",
                    marginTop: "auto",
                    marginBottom: "auto",
                    height: "50px",
                    boxShadow: "none",
                    borderRadius: "5px",
                  }}
                >
                  <InputBase
                    sx={{ flex: 1 }}
                    placeholder="Search ..."
                    inputProps={{ "aria-label": "   search" }}
                    onChange={setSearchFun}
                    value={search}
                  />
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    style={{
                      backgroundColor: "#FFAB00",
                      borderRadius: "10%",
                      color: "#fff",
                    }}
                    onClick={setSearchFuncall}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
            <Tooltip title="Add License">

                <IconButton
                  type="submit"
                  sx={{ p: "12px" }}
                  aria-label="search"
                  style={{
                    backgroundColor: "#00A1F1",
                    borderRadius: "10%",
                    marginTop: "4px",
                  }}
                  onClick={addlicense}
                >
                  <img src={addicon} style={{ color: "#FFFFFF" }} />
                </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
            {loading ? (
              <>
                <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                  <CustomLoader loading={loading} />
                </Grid>
              </>
            ) : orgLicData && orgLicData.length == 0 ? (
              <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                <Typography>No matching records found.</Typography>
              </Grid>
            ) : (
              <></>
            )}
            {!loading &&
              orgLicData &&
              orgLicData.map((rec, i) => {
                return (
                  <Grid item xs={12} md={6}>
                    <Box>
                      <Box
                        className="categorylic-item"
                        key={rec.id}
                        sx={{ cursor: "pointer", mt: "12px" }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={9}>
                            <Stack direction="column">
                              <Typography className="license-name">
                                {rec.name}
                              </Typography>
                              <Typography
                                style={{
                                  marginLeft: "10px",
                                  fontWeight: "500",
                                  fontSize: "15px",
                                  lineHeight: "15px",
                                  color: "#000",
                                }}
                              >
                                ${rec.price}/{rec.pricingType}
                              </Typography>
                              {rec.availableUnits > 0 ? (
                                <Typography
                                  style={{
                                    margin: "10px",
                                    fontWeight: "700",
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    color: "#00A1F1",
                                  }}
                                >
                                  {rec.availableUnits} available licenses
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    margin: "10px",
                                    fontWeight: "700",
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    color: "#F65314",
                                  }}
                                >
                                  No licenses available
                                </Typography>
                              )}
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={3} sx={{ textAlign: "end" }}>
                            <Stack
                              direction={{ xs: "row", sm: "row", md: "column" }}
                              sx={{ float: "right" }}
                            >
                              {rec.visibilityFlag == 1 ? (
                                <Box
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: "#7CBB00",
                                    width: "80px",
                                    borderRadius: "10px",
                                    margin: "10px",
                                  }}
                                  data-id={rec.id}
                                  data-flag={0}
                                  onClick={hideLicense}
                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="center"
                                  >
                                    <IconButton>
                                      <img src={oval}></img>
                                    </IconButton>
                                    <Typography
                                      style={{
                                        fontWeight: "700",
                                        color: "#FFF",
                                        fontSize: "12px",
                                        margin: "5px",
                                      }}
                                    >
                                      Show
                                    </Typography>
                                  </Stack>
                                </Box>
                              ) : (
                                <Box
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: "#F4F4F4",
                                    width: "80px",
                                    borderRadius: "10px",
                                    margin: "10px",
                                  }}
                                  data-id={rec.id}
                                  data-flag={1}
                                  onClick={hideLicense}
                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="center"
                                  >
                                    <Typography
                                      style={{
                                        fontWeight: "700",
                                        color: "#000",
                                        fontSize: "12px",
                                        margin: "5px",
                                      }}
                                    >
                                      Hide
                                    </Typography>

                                    <IconButton>
                                      <img src={oval}></img>
                                    </IconButton>
                                  </Stack>
                                </Box>
                              )}
                              <Box style={{ margin: "4px" }}>
            <Tooltip title="Delete">
                                <IconButton
                                  data-id={rec.id}
                                  onClick={handleDelete}
                                >
                                  <img src={deleteIcon}></img>
                                </IconButton>
                                </Tooltip>
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
        {open && (
          <AddNewLicense
            open={open}
            handleClose={handleClose}
            flag={true}
            orgid={orgid}
            licensData={licensData}
          />
        )}
        {openfil && (
          <OrgLicenseFilter
            open={openfil}
            handleClose={openFilter}
            setFilter={setFilter}
            filter={filter}
            setSearch={setSearch}
          />
        )}
      </Box>
    </>
  );
}
