import React, { useEffect, useState } from "react";
import Navbar from "../layout/Navbar";
import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import filicon from "../../images/filter-new.png";
import SearchIcon from "@mui/icons-material/Search";
import addicon from "../../images/addicon.png";
import MoreIcon from "../../images/more icon.png";
import editIcon from "../../images/editIcon.png";
import delIcon from "../../images/deleteicon.png";
import styled from "@emotion/styled";
import { getDistributionList } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import GroupDetails from "./GroupDetails";
import { getSorting, stableSort } from "../common/function";
import GroupsFilter from "./GroupsFilter";
import clrfilter from "../../images/clearfilter.svg";
import { useNavigate } from "react-router-dom";
import { authHeader, getSideFlag } from "../common/mainfunctions";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export default function GroupIndex() {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const open = Boolean(anchorEl);
  const [search, setSearch] = useState();
  const [view, setView] = useState(false);
  const [groupid, setGroupId] = useState();
  const [gropname, setGroupName] = useState();
  const [openfil, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState();
  const tok = authHeader();
  const glist = useSelector((gl) => gl.DistributionForListingReducer);
  useEffect(() => {
    dispatch(getDistributionList(search));
  }, [search]);
  useEffect(() => {
    if (filter) {
      dispatch(getDistributionList(filter));
    }
  }, [filter]);
  const searchFun = (e) => {
    setSearch(e.target.value);
  };
  const setSearchFun = () => {
    dispatch(getDistributionList(search));
  };
  const handleViewGroup = (e) => {
    setGroupId(e.currentTarget.dataset.id);
    setGroupName(e.currentTarget.dataset.name);
    setView(!view);
  };
  const openFilter = () => {
    setOpenFilter(!openfil);
  };
  const clearFilter = () => {
    dispatch(getDistributionList());
    setFilter();
  };
  useEffect(() => {
    if (!tok) {
      navigation("/signin");
    } else {
      console.log("tok", `*****${tok}`);
    }
  }, [tok]);
  return (
    <>
    {getSideFlag() == "true" ? (
          <></>
        ) : (
      <Navbar name="Groups" />)}

      <Grid
        sx={{ mt:getSideFlag()=="true"?1:10 }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      >
        <Grid xs={12} md={6}>
          <Typography className="group-head" style={{ paddingLeft: "16px" }}>
            Distribution Group
            <Tooltip title="Filter">
            <IconButton aria-label="delete" size="small" onClick={openFilter}>
              {/* <FilterAltIcon fontSize="inherit" /> */}
              <img src={filicon} />
            </IconButton>
            </Tooltip>
            {filter && (
            <Tooltip title="Clear filter">

              <IconButton
                aria-label="delete"
                sx={{ padding: "10px" }}
                size="small"
                onClick={clearFilter}
              >
                <img src={clrfilter} />
              </IconButton>
              </Tooltip>
            )}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Paper
            //   component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "-webkit-fill-available",
              marginTop: "auto",
              marginBottom: "auto",
              boxShadow: "none",
            }}
          >
            <InputBase
              sx={{ flex: 1 }}
              placeholder="Search ..."
              inputProps={{ "aria-label": "   search" }}
              onChange={searchFun}
              value={search}
            />
            <IconButton
              type="submit"
              sx={{ p: "10px" }}
              aria-label="search"
              style={{
                backgroundColor: "#FFAB00",
                borderRadius: "10%",
                color: "#FFF",
              }}
              onClick={setSearchFun}
            >
              <SearchIcon />
              {/* <img src={searchicon}/> */}
            </IconButton>
          </Paper>
        </Grid>
      </Grid>

      <div className="scr-activity1">
        {glist &&
          stableSort(glist, getSorting("asc", "displayName")).map((r, i) => {
            const {
              displayName = "",
              id = "",
              userPrincipalName = "",
              accountEnabled = "",
              officeLocation = "N/A",
              jobTitle = "N/A",
              department = "",
            } = r;
            const itemClass = i % 2 === 0 ? "even-item" : "odd-item";
            return (
              <>
                <Box
                  className={`bottom-boxgr line10 ${itemClass}`}
                  style={{ marginBottom: "12px" }}
                  onClick={handleViewGroup}
                  data-id={id}
                  data-name={displayName}
                  key={id}
                >
                  <Box className="line"></Box>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4" className="report-title">
                      {r.displayName || "N/A"}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      // className={classes.icoBtnInside}
                      style={{
                        backgroundColor: "none",
                        // marginLeft:'120px',
                        borderRadius: "10%",
                      }}
                      id={id}
                      key={id}
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      size="small"
                      //  onClick={setShownFun}
                    >
                      <img
                        src={MoreIcon}
                        style={{ width: "16px", height: "17px" }}
                      />
                    </IconButton>
                  </Stack>
                </Box>
              </>
            );
          })}
      </div>
      {view && (
        <GroupDetails
          open={view}
          handleClose={handleViewGroup}
          groupid={groupid}
          groupname={gropname}
        />
      )}
      {openfil && (
        <GroupsFilter
          open={openfil}
          handleClose={openFilter}
          setFilter={setFilter}
          setSearch={setSearch}
        />
      )}
    </>
  );
}
