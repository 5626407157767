import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import addicon from "../../../images/addicon.png";
import MoreIcon from "../../../images/more icon.png";
import editIcon from "../../../images/editIcon.png";
import delIcon from "../../../images/deleteicon.png";
import styled from "@emotion/styled";
import { useState } from "react";
import AddNewWorkFlowPopup from "../../organizationadd/AddNewWorkFlowPopup";
import DeleteWorkFlowPopup from "../../organizationadd/DeleteWorkflowPopup";
import { useEffect } from "react";
import {
  GET_WORKFLOW_DETAILS_URL,
  WORK_FLOW_LIST_URL,
  WORKFLOW_FLAG_CHANGE_URL,
} from "../../common/ApiUrl";
import { authHeader, CustomMessage, CustomMessageToast } from "../../common/mainfunctions";
import API from "../../common/API";
import CustomLoader from "../../common/CustomLoader";
import { debounce } from "underscore";
import { useRef } from "react";
import ToggleButton from "../../toggleButton";
import { useSnackbar } from "notistack";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export default function OrganizationWorkflow(props) {
  const { orgid, data, getOrgDetails } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [addworkflow, setAddWorkFlow] = useState(false);
  const [delpop, setdelpop] = useState(false);
  const [workflowList, setWorkflowList] = useState();
  const [editid, setEditId] = useState();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState();
  const [details, setDetails] = useState([]);
  const [workFlag, setWorkFlag] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const setSearchFun = (e) => {
    setSearch(e.target.value);
  };
  const getWorkflowDetails = async (id) => {
    const config = {
      method: "GET",
      url: GET_WORKFLOW_DETAILS_URL,
      headers: authHeader(),
      params: {
        workflowId: id,
      },
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        const { details } = data;
        setDetails(details);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const addNewWorkFlowFun = () => {
    setEditId();
    setAddWorkFlow(!addworkflow);
    setAnchorEl(null);
  };
  const setShownFun = (event) => {
    setEditId(event.currentTarget.dataset.id);
    getWorkflowDetails(event.currentTarget.dataset.id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleWorkflow = () => {
    setAddWorkFlow(!addworkflow);
  };
  const handleDelete = (e) => {
    setdelpop(!delpop);
  };
  const handleEdit = (e) => {
    setAddWorkFlow(!addworkflow);
    setAnchorEl(null);
  };
  const getWorkflowList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: WORK_FLOW_LIST_URL,
      headers: authHeader(),
      params: {
        organizationId: orgid,
        search: search,
      },
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;

      if (status == "Success") {
        setWorkflowList(data.list);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const successcall = () => {
    getWorkflowList();
    setAddWorkFlow(false);
    setdelpop(false);
    setAnchorEl(null);
  };
  const setSearchFuncall = () => {
    delaySearch(search);
  };
  const delaySearch = useRef(
    debounce((search) => getWorkflowList(search), 500)
  ).current;
  useEffect(() => {
    delaySearch(search);
  }, [search]);
  useEffect(()=>{
    setWorkFlag(data.orgDetails.adminWorkflowFlag);
  },[data])
  const handleChangeWorkflow = async () => {
    setWorkFlag(!workFlag);
    const config = {
      method: "GET",
      headers: authHeader(),
      url: WORKFLOW_FLAG_CHANGE_URL,
      params: { flag :workFlag==0?1:0},
    };
try{
  const response=await API(config)
  console.log(response)
   const { status, message } = response.data;
      if (status == "Success") {

                CustomMessageToast(message, "success", enqueueSnackbar);
                getOrgDetails()

      }
}catch(error){
   if (error.response) {
     const { message } = error.response.data;
     CustomMessage(message, "error", enqueueSnackbar);
   }
}
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Grid
          sx={{ mt: 1.5, pl: 2 }}
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} md={6}>
            <Typography
              className="acti-log"
              style={{
                fontSize: "24px",
                fontWeight: "500",
                lineHeight: "50px",
              }}
            >
              Workflow List
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper
              sx={{
                p: "2px 6px",
                display: "flex",
                alignItems: "center",
                width: "-webkit-fill-available",
                marginTop: "auto",
                marginBottom: "auto",
                height: "50px",
                boxShadow: "none",
                borderRadius: "5px",
              }}
            >
              <InputBase
                sx={{ flex: 1 }}
                placeholder="Search ..."
                inputProps={{ "aria-label": "   search" }}
                onChange={setSearchFun}
                value={search}
              />
              <IconButton
                type="submit"
                sx={{ p: "10px" }}
                aria-label="search"
                style={{
                  backgroundColor: "#FFAB00",
                  borderRadius: "10%",
                  color: "#fff",
                }}
                onClick={setSearchFuncall}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item xs={12} md={1}>
          <Tooltip title="Add workflow">

            <IconButton
              type="submit"
              sx={{ p: "12px" }}
              aria-label="search"
              style={{
                backgroundColor: "#00A1F1",
                borderRadius: "10%",
              }}
              onClick={addNewWorkFlowFun}
            >
              <img src={addicon} style={{ color: "#FFFFFF" }} />
            </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Box sx={{ ml: "30px", mr: "30px", mt: "10px", mb: "10px" }}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            style={{ marginLeft: "-12px" }}
          >
            <ToggleButton
              name="teamsandemail"
              id="teamsandemail"
              value={workFlag}
              onChange={() => handleChangeWorkflow()}
              // disabled={!editState}
            />
            <Typography className="notify-text" style={{ marginLeft: "10px" }}>
              Allow Admin to Bypass Workflow
            </Typography>
          </Stack>
          <Grid
            className="work-box"
            sx={{ mt: 1.5, pl: 2, backgroundColor: "#FFF" }}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={3}>
              <Typography className="workflow-typo">Requester</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className="workflow-typo">REQUESTER TYPE</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className="workflow-typo">APPROVER</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography className="workflow-typo">APPROVER TYPE</Typography>
            </Grid>
            <Grid item xs={12} md={1}></Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: "2px", textAlign: "center" }}>
          <CustomLoader loading={loading} />
        </Box>
        {!loading && workflowList && workflowList.length == 0 ? (
          <Box sx={{ mt: "2px", textAlign: "center" }}>
            <Typography>No matching records found.</Typography>
          </Box>
        ) : (
          <></>
        )}

        {!loading &&
          workflowList &&
          workflowList.map((r, i) => {
            const { requesterName, requesterType, approverName, approverType } =
              r;
            return (
              <Box
                className="work-flow"
                key={r.id}
                sx={{ ml: "30px", mr: "30px", mt: "10px", mb: "10px" }}
              >
                <Grid
                  className="work-content-box"
                  sx={{ mt: 1.5, pl: 2, backgroundColor: "#FFF" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} md={3}>
                    <Typography className="content-work-title">
                      {requesterName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="content1-work-title">
                      {requesterType}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="content-work-title">
                      {approverName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography className="content1-work-title">
                      {approverType}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Box>
                      <IconButton
                        id={r.id}
                        key={r.id}
                        aria-controls={
                          open ? "demo-customized-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        variant="contained"
                        disableElevation
                        data-id={r.id}
                        onClick={setShownFun}
                      >
                        <img src={MoreIcon} />
                      </IconButton>
                    </Box>
                  </Grid>
                  <StyledMenu
                    id={r.id}
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleEdit} disableRipple data-id={r.id}>
                      <img style={{ pr: "20px" }} src={editIcon} />
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={handleDelete}
                      disableRipple
                      data-id={r.id}
                    >
                      <img style={{ pr: "20px" }} src={delIcon} />
                      Delete
                    </MenuItem>
                  </StyledMenu>
                </Grid>
              </Box>
            );
          })}
      </Box>
      {addworkflow && (
        <AddNewWorkFlowPopup
          open={addworkflow}
          handleClose={handleWorkflow}
          flag={true}
          orgid={orgid}
          id={editid}
          successcall={successcall}
          details={details}
        />
      )}
      {delpop && (
        <DeleteWorkFlowPopup
          open={delpop}
          handleClose={handleDelete}
          id={editid}
          successcall={successcall}
        />
      )}
    </Box>
  );
}
