import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import closeIcon from "../../images/close.png";
import React, { useEffect, useState } from "react";
import oval from "../../images/Oval-white.png";
import editIcon from "../../images/editIcon.png";
import delIcon from "../../images/deleteicon.png";
import { Label } from "@mui/icons-material";
import {
  DELETE_USER_FROM_AZURE_URL,
  GET_USER_BY_ID,
  LICENCE_REQUEST_DETAILS_BY_ID_URL,
  SYSTEM_LICENSE_LIST_URL,
  UPDATE_USER_URL,
  USERS_ENABLE_WITH_REMOVE_FORWARD_URL,
  USER_LISTS_URL,
} from "../common/ApiUrl";
import {
  CustomMessage,
  authHeader,
  getOrgId,
  getRole,
} from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import CustomLoader from "../common/CustomLoader";
import { useSelector } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import { useRef } from "react";
import boxicon from "../../images/boxicon.png";
import UserDisableConfirmPopup from "./UserDisableConfirmPopup";

const style = {
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  background: "#FFFFFF",
  boxShadow: 24,
  padding: "30px",
  overflowY: "scroll",
};
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#7cbb00",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const IOSSwitchRed = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#F65314",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const BootstrapInputSel = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "90%",
    border: "1px solid #cccccc",
    padding: "4px 6px",
    color: "#343434",
    borderRadius: "2px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const initialValues = {
  displayName: "",
  userPrincipalName: "",
  department: "N/A",
  officeLocation: "N/A",
  jobTitle: "N/A",
  accountEnabled: true,
  license: "",
  firstName: "",
  lastName: "",
  grouparr: [],
};
export default function ViewUserDetailsPopup(props) {
  const { open, handleClose, userid, userData, getUsersList, flag } = props;
  const [data, setData] = useState();
  const [license, setLicense] = useState();
  const [licenses, setLicenses] = useState();
  const [personName, setPersonName] = React.useState([]);
  const [groupdet, setGroupDetails] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [editview, setEditView] = useState(flag);
  const [loading, setLoading] = useState(false);
  const [detLoading, setDetLoading] = useState(false);
  const [selectedone, setSelectedone] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDoneButton, setShowDoneButton] = useState(false);
  const [allItemsAreSelected, setAllItemsAreSelected] = useState(false);
  const [dscl, setDoneClick] = useState("no");
  const inputRef = useRef(null);
  const [checked, setChecked] = useState({});
  const [sids, setIds] = useState([]);
  const [variantName, setVariantName] = React.useState([]);
  const [oldLicList, setOldLicList] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [enableid, setId] = useState();
  const [userPrinName, setuserPrincipalName] = useState();
  const [loadingdis, setLoadingDis] = useState(false);
  const deplist = useSelector((dl) => dl.DepartmentReducer);
  const offlist = useSelector((ol) => ol.OfficeListReducer);
  const jtlist = useSelector((jl) => jl.JobTitleReducer);
  const glist = useSelector((gl) => gl.DistributionGroupListReducer);
  const multiSelectRef = useRef();
  const handleDoneButtonClick = () => {
    setDropdownOpen(!dropdownOpen);
    setAnchorEl(null);

    multiSelectRef.current.focus(); // Remove focus
    // }
  };
  const editViewFun = () => {
    setEditView(false);
  };

  function findMatchingUpns(ids, arr) {
    const matchingUpns = [];
    for (const id of ids) {
      const matchedItem = arr.find((item) => item.id === id);
      if (matchedItem) {
        matchingUpns.push(matchedItem.upn);
      }
    }
    const upnString = matchingUpns.join(",");

    return upnString;
  }

  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      //  addNewUser(values)
    },
  });
  useEffect(() => {
    if (flag == "false") {
      setEditView(false);
    }
  }, [flag]);
  const getUserDetails = async () => {
    setDetLoading(true);
    const options = {
      method: "GET",
      url: `${GET_USER_BY_ID}/${userid}`,
      headers: authHeader(),
    };

    try {
      const apiResponse = await API(options);
      const { status, data } = apiResponse.data;

      if (status === "Success") {
        setData(data);

        const {
          displayName,
          userPrincipalName,
          department,
          officeLocation,
          jobTitle,
          accountEnabled,
          licenseDetails,
          givenName,
          surname,
          groupDetails,
        } = data;

        // Handle license details
        if (licenseDetails.value.length > 0) {
          // Filter licenses from the list
          let licensesFromData = licenseDetails.value
            .map((license) => {
              // Find license in the local licenses array
              return licenses.find((r) => r.licenseSkuId === license.skuId);
            })
            .filter(Boolean); // Remove undefined entries

          console.log("Filtered licenses", licensesFromData);

          // Update variantName with all filtered licenses
          setVariantName(licensesFromData);

          // Update checked state for all licenses
          const newChecked = {};
          licensesFromData.forEach((license) => {
            newChecked[license.id] = true;
          });
          setChecked(newChecked);

          // Update oldLicList with the filtered licenses
          setOldLicList(licensesFromData);
        } else {
          setOldLicList([]);
          setVariantName([]);
        }

        // Handle group details
        if (groupDetails.value.length > 0) {
          setGroupDetails(groupDetails.value);
          const transformedArray = groupDetails.value.map((item) => ({
            label: item.displayName,
            id: item.id,
            value: item.displayName,
          }));
          setSelectedone(transformedArray);
        }

        // Update user details
        setValues({
          department: department,
          displayName: displayName,
          userPrincipalName: userPrincipalName,
          officeLocation: officeLocation,
          jobTitle: jobTitle,
          accountEnabled: accountEnabled,
          firstName: givenName,
          surname: surname,
          license:
            licenseDetails.value.length > 0
              ? licenseDetails.value[0].skuPartNumber
              : "",
        });
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        // CustomMessage(message, "error", enqueueSnackbar)
      }
    } finally {
      setDetLoading(false);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [userid, license]);
  const getOrgLicenseList = async () => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: SYSTEM_LICENSE_LIST_URL,
      params: {
        organizationId: getOrgId(),
      },
    };
    try {
      const response = await API(options);
      const { status } = response.data;

      if (status == "Success") {
        const { status, data } = response.data;
        setLicenses(data.list);
        setLicense(data.list);
      } else {
        console.log("error");
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
      }
    }
  };
  useEffect(() => {
    getOrgLicenseList();
  }, [userid]);

  //    const {
  // displayName='',
  // userPrincipalName='',
  // department='N/A',
  // officeLocation='N/A',
  // jobTitle='N/A',
  // accountEnabled=true,
  //    }=data||[]
  const handleDeleteYes = async () => {
    const config = {
      method: "DELETE",
      url: DELETE_USER_FROM_AZURE_URL,
      headers: authHeader(),
      data: {
        userId: userid,
      },
    };
    try {
      const response = await API(config);
      const { data, message, status } = response.data;
      if (status == "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
        getUsersList();
      }
    } catch (error) {
      console.error(error);
    }
  };

  function arraysAreSame(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false; // If the arrays have different lengths, they can't be the same.
    }

    return arr1.every((item1) => {
      // Check if the corresponding item in arr2 has the same id.
      const matchingItem2 = arr2.find((item2) => item1.id === item2.id);
      return matchingItem2;
    });
  }
  function isOldArrPresentInNewArr(oldarr, newarr) {
    for (const oldItem of oldarr) {
      // Check if an element with the same 'id' exists in newarr.
      const matchingItem = newarr.find((newItem) => oldItem.id === newItem.id);

      if (!matchingItem) {
        return false; // If any 'id' from oldarr is not found in newarr, return false.
      }
    }

    return true; // All 'id' values from oldarr were found in newarr.
  }
  const updateDetails = async () => {
    // Initialize the arrays to store new and removed licenses
    let newarr1 = [];
    let removearr1 = [];
    let newLicenses = [];
    let removeLicenses = [];

    // Identify licenses to remove (present in oldLicList but not in variantName)
    removeLicenses = oldLicList.filter(
      (oldLic) => !variantName.some((newLic) => newLic.id === oldLic.id)
    );

    // Identify new licenses to add (present in variantName but not in oldLicList)
    newLicenses = variantName.filter(
      (newLic) => !oldLicList.some((oldLic) => oldLic.id === newLic.id)
    );

    // Map the license SKU IDs or whatever unique identifier is used
    let removeLicenseIds =
      removeLicenses.map((item) => item.licenseSkuId).join(",") || "";
    let addNewLicense = newLicenses.map(
      ({ availableUnits, createdDate, isDelete, ...rest }) => rest
    );

    // Prepare the list of User Principal Names (UPNs)
    let up1 = [...newLicenses, ...removeLicenses].map((it) => it.id).join(",");
    let upnlist1 = findMatchingUpns(up1.split(","), glist);

    console.log("final", removeLicenseIds, addNewLicense);

    // Set the loading state
    setLoading(true);

    const config = {
      method: "POST",
      headers: authHeader(),
      url: UPDATE_USER_URL,
      data: {
        userId: userid,
        department: values.department,
        jobTitle: values.jobTitle,
        officeLocation: values.officeLocation,
        mail: values.userPrincipalName,
        firstName: values.firstName,
        lastName: values.lastName,
        removeLicense: removeLicenseIds,
        addNewLicense: addNewLicense.length > 0 ? addNewLicense : [],
        userPrincipalName: upnlist1,
        addGroups: newarr1.map((it) => it.id).join(","),
        removeGroups: removearr1.map((it) => it.id).join(","),
      },
    };

    try {
      const response = await API(config);
      const { status } = response.data;
      if (status === "Success") {
        CustomMessage(
          "Updated successfully. It will take time to reflect the changes.",
          "success",
          enqueueSnackbar
        );
        setTimeout(() => {
          getUserDetails();
        }, 5000);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }
  };

  const selectDoneFun = (e) => {
    setDoneClick(e.currentTarget.dataset.flag);
    // setMenuOpen(true);
  };
  const customOptionRenderer = ({ checked, option, onClick }) => (
    <>
      <div key={option.value}>
        <label>
          <input
            style={{ opacity: option.label == "done" ? "0" : "" }}
            type="checkbox"
            className="option-checkbox"
            checked={option.label == "done" ? true : checked}
            onChange={() => {
              onClick(option);

              if (option.label != "done") {
                setDropdownOpen(false);
              }
              setShowDoneButton(true);
            }}
          />
          {option.label != "done" ? option.label : ""}
        </label>
      </div>
      <div>
        {showDoneButton && option === glist[glist.length - 1] && (
          <Box style={{ textAlign: "end" }}>
            <Button onClick={handleDoneButtonClick}>Done</Button>
          </Box>
        )}
      </div>
    </>
  );
  useEffect(() => {
    if (selectedone.length == glist.length) {
      setAllItemsAreSelected(true);
    } else {
      setAllItemsAreSelected(false);
    }
  }, [selectedone.length]);
  const selectLicense = (e) => {
    const { checked, id } = e.target;
    console.log(checked);
    // Find the selected license by its ID
    const selectedLicense = licenses.find((r) => r.id == id);

    // Update the checked state
    setChecked((prev) => ({
      ...prev,
      [id]: checked,
    }));

    // Update the selected IDs based on the checked state
    setIds((prevIds) =>
      checked ? [...prevIds, { id: id }] : prevIds.filter((e) => e.id != id)
    );
    if (checked) {
      console.log(sids);
    }
    // Update the variantName state to include only checked licenses
    setVariantName((prev) => {
      if (checked) {
        return [...prev, selectedLicense];
      } else {
        return prev.filter((item) => item.id != id);
      }
    });

    // Update the license name in values
    setValues((prevValues) => ({
      ...prevValues,
      license: checked ? selectedLicense?.name : "",
    }));
  };

  console.log(variantName);
  const handleInputClick = () => {
    if (dscl == "no") {
      setMenuOpen(true); // Open the menu when the input field is clicked
    } else if (dscl == "done") {
      setMenuOpen(false);
      setTimeout(() => {
        setDoneClick("no");
      }, 100);
    } else {
      setMenuOpen(true);
    }
  };
  useEffect(() => {
    if (dscl == "done") {
      handleInputClick();
    }
  }, [dscl]);
  const handleClickOpen = (e) => {
    const id = e.currentTarget.dataset.enableid;
    const userprinname = e.currentTarget.dataset.userprinname;
    // setId(id);
    // setuserPrincipalName(userprinname);
    setOpenPop(true);
  };
  const handleCloseConfirm = () => {
    setOpenPop(false);
    setTimeout(() => {
      getUserDetails();
    }, 5000);
    setTimeout(() => {
      getUserDetails();
    }, 5000);
  };
  const handleEnableUSer = async (e) => {
    setLoadingDis(true);
    const config = {
      method: "GET",
      url: USERS_ENABLE_WITH_REMOVE_FORWARD_URL,
      headers: authHeader(),
      params: {
        disableUserId: userid,
        disableUserPrincipalName: values.userPrincipalName,
        removeForwardingStatus: "yes",
      },
    };
    try {
      const response = await API(config);
      const { data, message, status } = response.data;
      if (status == "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
        getUsersList();
        setTimeout(() => {
          getUsersList();
          getUserDetails();
        }, 5000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDis(false);
    }
  };
  return (
    <Modal
      className="userlt-popup adnew-usr-popup"
      open={open}
      // onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        sx={{ ...style }}
        className="manage-expense-transaction side-scroll-list"
      >
        <Grid container xs={12}>
          <Grid item xs={2}>
            <img src={closeIcon} onClick={handleClose} />
          </Grid>
          <Grid item xs={3}></Grid>

          <Grid item xs={5}>
            {values.accountEnabled == true ? (
              <Box
                className="enable-btn"
                // data-enableid={values.id}
                // data-userprinname={values.userPrincipalName}
                onClick={handleClickOpen}
              >
                <FormControlLabel
                  style={{ marginLeft: "2px" }}
                  control={
                    <IOSSwitch sx={{ m: 1 }} checked={true} disabled={true} />
                  }
                  label="Enabled"
                />
              </Box>
            ) : (
              <Box className="disable-btn" onClick={handleEnableUSer}>
                <FormControlLabel
                  style={{ marginLeft: "2px" }}
                  control={
                    <IOSSwitchRed
                      sx={{ m: 1 }}
                      checked={true}
                      disabled={true}
                    />
                  }
                  label="Disabled"
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={1} sx={{ padding: "12px", mt: "-10px" }}>
          <Tooltip title="Edit">

            <img src={editIcon} onClick={editViewFun} />
            </Tooltip>
          </Grid>
          <Grid item xs={1} sx={{ padding: "12px", mt: "-10px" }}>
          <Tooltip title="Delete">

            <img src={delIcon} onClick={handleDeleteYes} />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            {detLoading && <CustomLoader loading={detLoading} />}
            {loadingdis && <CustomLoader loading={loadingdis} />}
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        <Typography className="usr-name">{data && data.displayName}</Typography>
        <Typography className="usr-list-mail">
          {data && data.userPrincipalName}
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={{ xs: 0, sm: 0, md: 2 }}
          // justifyContent="space-between"
          style={{ marginTop: "10px" }}
        >
          <Typography className="user-field" style={{ minWidth: "90px" }}>
            DEPARTMENT
          </Typography>
          {editview ? (
            <FormControl sx={{ width: "100%" }} variant="standard">
              <BootstrapInput
                disabled
                name="department"
                id="department"
                value={values.department}
                placeholder="department"
              ></BootstrapInput>
              <FormHelperText error={true}>
                {errors.department && touched.department
                  ? errors.department
                  : ""}
              </FormHelperText>
            </FormControl>
          ) : (
            <FormControl sx={{ width: "100%" }} variant="standard">
              <BootstrapInputSel
                ref={multiSelectRef}
                disabled={editview}
                name="department"
                id="department"
                value={values.department}
                onChange={handleChange}
                placeholder="department"
              >
                <MenuItem selected={true} disabled={true} value="Department">
                  Department
                </MenuItem>

                {deplist &&
                  deplist.map((rec, i) => {
                    return (
                      <MenuItem key={`depart_${i}`} value={rec.name}>
                        {rec.name}
                      </MenuItem>
                    );
                  })}
              </BootstrapInputSel>
              <FormHelperText error={true}>
                {errors.department && touched.department
                  ? errors.department
                  : ""}
              </FormHelperText>
            </FormControl>
          )}
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={{ xs: 0, sm: 0, md: 2 }}
          // justifyContent="space-between"
          style={{ marginTop: "10px" }}
        >
          <Typography className="user-field">OFFICE</Typography>
          {editview ? (
            <FormControl sx={{ width: "100%" }} variant="standard">
              <BootstrapInput
                disabled
                name="officeLocation"
                id="officeLocation"
                value={values.officeLocation}
                placeholder="officeLocation"
              ></BootstrapInput>
            </FormControl>
          ) : (
            <FormControl sx={{ width: "100%" }} variant="standard">
              <BootstrapInputSel
                disabled={editview}
                labelId="officeLocation"
                id="officeLocation"
                name="officeLocation"
                value={values.officeLocation}
                label="officeLocation"
                onChange={handleChange}
              >
                <MenuItem disabled={true} value={"Office"}>
                  Office
                </MenuItem>

                {offlist &&
                  offlist.map((rec, i) => {
                    return (
                      <MenuItem key={`off_loc_${i}`} value={rec.name}>
                        {rec.name}
                      </MenuItem>
                    );
                  })}
              </BootstrapInputSel>
              <FormHelperText error={true}>
                {errors.officeLocation && touched.officeLocation
                  ? errors.officeLocation
                  : ""}
              </FormHelperText>
            </FormControl>
          )}
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={{ xs: 0, sm: 0, md: 2 }}
          // justifyContent="space-between"
          style={{ marginTop: "10px" }}
        >
          {/* <Typography variant="h6">ROLE</Typography>
<TextField 
disabled
id="outlined-basic" label="" value="Human Resources" variant="outlined" />
    </Stack>
    <Stack
     direction="row"
    spacing={4}
    justifyContent="space-between"
    style={{marginTop:'10px'}}

    > */}
          <Typography className="user-field">JOB TITLE</Typography>
          {editview ? (
            <FormControl sx={{ width: "100%" }} variant="standard">
              <BootstrapInput
                disabled
                name="jobTitle"
                id="jobTitle"
                value={values.jobTitle}
                placeholder="jobTitle"
              ></BootstrapInput>
            </FormControl>
          ) : (
            <FormControl sx={{ width: "100%" }} variant="standard">
              <BootstrapInputSel
                disabled={editview}
                labelId="jobTitle"
                id="jobTitle"
                name="jobTitle"
                value={values.jobTitle}
                label="jobTitle"
                onChange={handleChange}
              >
                <MenuItem selected={true} disabled={true} value="Job Title">
                  Job Title
                </MenuItem>

                {jtlist &&
                  jtlist.map((rec, i) => {
                    return (
                      <MenuItem key={`job_${i}`} value={rec.jobTitle}>
                        {rec.jobTitle}
                      </MenuItem>
                    );
                  })}
              </BootstrapInputSel>
              <FormHelperText error={true}>
                {errors.jobTitle && touched.jobTitle ? errors.jobTitle : ""}
              </FormHelperText>
            </FormControl>
          )}
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={{ xs: 0, sm: 0, md: 2 }}
          // justifyContent="space-between"
          style={{ marginTop: "10px" }}
        >
          <Typography className="user-field">GROUP</Typography>
          {/* <Box className="lic-box">
          {groupdet &&
            groupdet.map((r, i) => {
              return (
                  <Typography sx={{ padding: "10px" }} className="lic-name">
                    {r.displayName}
                  </Typography>
              );
            })}
                </Box> */}
          <FormControl
            style={{ marginTop: "10px" }}
            sx={{ width: "90%", minWidth: "100px", backgroundColor: "#f8f8f8" }}
            variant="standard"
          >
            <MultiSelect
              style={{ backgroundColor: "#f8f8f8" }}
              id="group"
              open={false}
              name="group"
              options={glist}
              value={
                selectedone.length == glist.length
                  ? selectedone
                  : selectedone.filter((el) => el.label != "done")
              }
              // value={selectedone}
              onChange={setSelectedone}
              labelledBy="Group"
              closeOnChangedValue={dropdownOpen}
              ItemRenderer={customOptionRenderer}
              // onMenuOpen={() => setDropdownOpen(true)}
              //   onMenuClose={() => setDropdownOpen(false)}
              overrideStrings={{
                selectSomeItems: "Groups",
                allItemsAreSelected:
                  selectedone.length == 2
                    ? selectedone.map((item) =>
                        item.label != "done" ? item.label : ""
                      )
                    : "All Groups are selected",
                search: "Search",
              }}
              allItemsAreSelected={
                glist.length != 2 && selectedone.length == glist.length - 1
                  ? true
                  : false
              }
              disabled={getRole() == "Admin" ? editview : true}
              // disabled
            />
            <FormHelperText error={true}>
              {errors.group && touched.group ? errors.group : ""}
            </FormHelperText>
          </FormControl>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={{ xs: 0, sm: 0, md: 2 }}
          // justifyContent="space-between"
          style={{ marginTop: "10px" }}
        >
          <Typography className="user-field">LICENSE(S)</Typography>
          {/* {license &&
            license.map((r, i) => {
              return (
                <Box className="lic-box">
                  <Typography sx={{ padding: "10px" }} className="lic-name">
                    {r.skuPartNumber}
                  </Typography>
                </Box>
              );
            })} */}
          <div>
            <FormControl
              style={{ marginTop: "10px" }}
              sx={{ width: "100%", maxWidth: "250px" }}
              variant="standard"
              label="License"
              placeholder="License"
            >
              <BootstrapInputSel
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                disabled={
                  getRole() != "Admin"
                    ? true
                    : getRole() == "Admin" && editview != false
                }
                // disabled={getRole()!="Admin"&&editview==false?false:true}
                value={variantName}
                label="License"
                placeholder="License"
                MenuProps={{ open: menuOpen }}
                data-flag="no"
                inputProps={{ onFocus: handleInputClick }}
                inputRef={inputRef}
                input={<OutlinedInput label="License" placeholder="license" />}
                renderValue={(selected) =>
                  selected.map((x) => x.name).join(", ")
                }
              >
                {licenses &&
                  licenses.map((rec) => (
                    <MenuItem key={rec.id} value={rec} data-flag="no">
                      <FormControlLabel
                        sx={{ margin: "0px !important" }}
                        id={rec.id}
                        skuname={rec.licenseSkuId}
                        name="exceptionRoleIdList"
                        onChange={selectLicense}
                        control={
                          <Checkbox
                            icon={<img src={boxicon} />}
                            id={rec.id}
                            checked={checked[rec.id]}
                            disabled={rec.visibilityFlag==0}
                          />
                        }
                      />
                      <ListItemText>
                        <Box className="categorylic-item">
                          <Grid container spacing={1}>
                            <Grid item xs={12} md={9}>
                              <Stack direction="row">
                                <Stack direction="column">
                                  <Typography
                                    className="drop-title"
                                    style={{
                                      margin: "10px",
                                      fontWeight: "500",
                                      fontSize: "20px",
                                      lineHeight: "20px",
                                      color: "#000",
                                    }}
                                  >
                                    {rec.name}
                                  </Typography>
                                  <Typography
                                    style={{
                                      marginLeft: "10px",
                                      fontWeight: "500",
                                      fontSize: "15px",
                                      lineHeight: "15px",
                                      color: "#000",
                                    }}
                                  >
                                    ${rec.price}/{rec.pricingType}
                                  </Typography>
                                  {rec.availableUnits > 0 ? (
                                    <Typography
                                      style={{
                                        margin: "10px",
                                        fontWeight: "700",
                                        fontSize: "12px",
                                        lineHeight: "15px",
                                        color: "#00A1F1",
                                      }}
                                    >
                                      {rec.availableUnits} available licenses
                                    </Typography>
                                  ) : (
                                    <Typography
                                      style={{
                                        margin: "10px",
                                        fontWeight: "700",
                                        fontSize: "12px",
                                        lineHeight: "15px",
                                        color: "#F65314",
                                      }}
                                    >
                                      No licenses available
                                    </Typography>
                                  )}
                                    {rec.visibilityFlag==0&&
                                    <Typography
                                    style={{
                                      margin: "10px",
                                      fontWeight: "700",
                                      fontSize: "12px",
                                      lineHeight: "15px",
                                      color: "#F65314",
                                    }}
                                  >
                                This license is hidden by the admin and cannot be used.
                                  </Typography>
}
                                </Stack>
                              </Stack>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ textAlign: "end" }}>
                              {/* <Stack direction="column">
                                  {rec.visibilityFlag == 1 ? (
                                    <Box
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#7CBB00",
                                        width: "60px",
                                        borderRadius: "10px",
                                        margin: "10px",
                                      }}
                                      data-id={rec.licenseId}
                                      data-flag={0}
                                    >
                                      <Stack
                                        direction="row"
                                        justifyContent="center"
                                      >
                                        <IconButton>
                                          <img src={oval}></img>
                                        </IconButton>
                                        <Typography
                                          style={{
                                            fontWeight: "700",
                                            color: "#FFF",
                                            fontSize: "12px",
                                            margin: "5px",
                                          }}
                                        >
                                          Show
                                        </Typography>
                                      </Stack>
                                    </Box>
                                  ) : (
                                    <Box
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#F4F4F4",
                                        width: "60px",
                                        borderRadius: "10px",
                                        margin: "10px",
                                      }}
                                      data-id={rec.licenseId}
                                      data-flag={1}
                                    >
                                      <Stack
                                        direction="row"
                                        justifyContent="center"
                                      >
                                        <Typography
                                          style={{
                                            fontWeight: "700",
                                            color: "#000",
                                            fontSize: "12px",
                                            margin: "5px",
                                          }}
                                        >
                                          Hide
                                        </Typography>

                                        <IconButton>
                                          <img src={oval}></img>
                                        </IconButton>
                                      </Stack>
                                    </Box>
                                  )}
                                  <Box style={{ margin: "4px" }}>
                                    <IconButton
                                      data-id={rec.licenseId}
                                    >
                                    </IconButton>
                                  </Box>
                                </Stack> */}
                            </Grid>
                          </Grid>
                        </Box>
                      </ListItemText>
                    </MenuItem>
                  ))}
                <Button data-flag="done" onClick={selectDoneFun}>
                  Done
                </Button>
              </BootstrapInputSel>
            </FormControl>
          </div>
          {/* <TextField
disabled
id="license" label="" 
name="license"
value={license&&license[0].skuPartNumber} variant="outlined" /> */}
        </Stack>
        {editview == false && (
          <Box
            style={{ borderRadius: "5px", textAlign: "center", width: "100%" }}
          >
            <Button
              style={{
                color: "#FFFFFF",
                backgroundColor: "#00A1F1",
                borderRadius: "5px",
                margin: "5px",
              }}
              type="submit"
              onClick={updateDetails}
            >
              {loading && <CustomLoader loading={loading ? true : false} />}
              SAVE CHANGES
            </Button>
          </Box>
        )}
        {openPop && (
          <UserDisableConfirmPopup
            open={openPop}
            handleClose={handleCloseConfirm}
            userid={userid}
            userPrinName={values.userPrincipalName}
          />
        )}
      </Box>
    </Modal>
  );
}
