export const BASE_API_URL = process.env.REACT_APP_BASE_URL;

export const LOGIN_URL = BASE_API_URL + "user/login";
//Dashboard
export const DASHBOARD_LICENSE_URL =
  BASE_API_URL + "dashboard/licenseRequestList";
export const DASHBOARD_ORGANIZATION_LIST =
  BASE_API_URL + "dashboard/organizationDetailsByUser";
export const DASHBOARD_ACTIVITY_LOG_LIST_URL =
  BASE_API_URL + "activitylog/activityLogsList";

//organization
export const ORGANIZATIONLIST_URL =
  BASE_API_URL + "organization/organizationList";
export const ORGANIZATION_DELETE =
  BASE_API_URL + "organization/deleteOrganization";
export const EDIT_ORGANIZATION_DETAILS =
  BASE_API_URL + "organization/editOrganization";
export const GET_ORGANIZATION_DETAILS =
  BASE_API_URL + "organization/organizationDetails";
export const CREATE_ORGANIZATION_URL =
  BASE_API_URL + "organization/createOrganization";

//common
export const STATE_LIST_URL = BASE_API_URL + "master/stateList";
export const ZIP_CODE_LIST_URL = BASE_API_URL + "master/zipcodeList";

export const FILE_UPLOAD_URL = BASE_API_URL + "user/uploadFile";

// microsoft
export const MICROSOFT_AUTHENTICATION_URL =
  BASE_API_URL + "microsoftApi/userAuthLogin";
export const VERIFY_AZURE_URL =
  BASE_API_URL + "microsoftApi/verifyAzureAccount";

export const USER_PROFILE_URL = BASE_API_URL + "user/profile";
export const AZURE_CONNECT_URL = BASE_API_URL + "microsoftApi/connectAzure";
export const AZURE_DISCONNECT_URL =
  BASE_API_URL + "microsoftApi/disconnectAzure";

//users
export const USER_LISTS_URL = BASE_API_URL + "user/userList";
export const GET_DEPARTMENT_LIST_URL =
  BASE_API_URL + "departments/departmentsList";
export const GET_OFFICE_LIST_URL = BASE_API_URL + "offices/officesList";
export const GET_ROLES_LIST_URL = BASE_API_URL + "roles/rolesList";
export const GET_JOB_TITILE_URL = BASE_API_URL + "jobTitles/jobTitlesList";
export const GET_LICENCE_LIST_URL = BASE_API_URL + "microsoftApi/licensesList";

export const ADD_NEW_USER_URL = BASE_API_URL + "user/createUser";
export const UPDATE_USER_URL = BASE_API_URL + "user/updateUser";
export const GET_USER_BY_ID = BASE_API_URL + "user/userDetails";

//Group
export const GET_GROUP_LIST_DROP_URL = BASE_API_URL + "group/groupList";
export const DELETE_GROUP_URL = BASE_API_URL + "group/deleteGroup";
export const ADD_NEW_GROUP_URL = BASE_API_URL + "group/addGroup";
export const GET_GROUP_LIST_AZURE_URL = BASE_API_URL + "group/findGroupFromMS";
export const GET_DISTRIBUTION_GROUP_LIST_URL =
  BASE_API_URL + "group/distributionGroupList";
export const GET_GROUP_MEMBER_LIST_URL = BASE_API_URL + "group/groupMembers";

//Membership
export const GET_MEMBERSHIP_LIST_URL =
  BASE_API_URL + "membership/membershipList";
// export const GET_MEMBERSHIP_AZURE_LIST_URL=BASE_API_URL+"membership/get_membership_membershipList"
export const ADD_USER_TO_GROUP = BASE_API_URL + "membership/addUserToGroup";

//Pax8
export const VERFIY_PAX8_URL = BASE_API_URL + "pax8Api/verifyPax8Account";
export const CONNECT_PAX8_URL = BASE_API_URL + "pax8Api/connectPax8";
export const DISCONNECT_PAX8_URL = BASE_API_URL + "pax8Api/disconnectPax8";

//approval request
export const APPROVAR_LIST_URL = BASE_API_URL + "requestLicense/approversList";
export const CREATE_NEW_APPROVAL_REQUEST_URL =
  BASE_API_URL + "requestLicense/newRequest";
export const LICENSE_REQUEST_LIST_URL =
  BASE_API_URL + "requestLicense/licenseRequestList";
export const CHANGE_LICENSE_STATUS =
  BASE_API_URL + "requestLicense/changeStatus";

export const LICENCE_REQUEST_DETAILS_BY_ID_URL =
  BASE_API_URL + "requestLicense/details";
export const UPDATE_LICENCE_REQUEST_URL = BASE_API_URL + "requestLicense/edit";

export const SYSTEM_LICENSE_LIST_URL = BASE_API_URL + "licenses/licensesList";

export const DELETE_USER_FROM_AZURE_URL = BASE_API_URL + "user/deleteUser";
export const ENABLE_DISABLE_USER_URL = BASE_API_URL + "user/disableUser";
export const PRODUCT_ID_PRICE_API_URL =
  BASE_API_URL + "microsoftApi/checkProductIdAndPrice";

// system user
export const SYSTEM_USER_LIST = BASE_API_URL + "user/systemUserList";
export const ADD_SYSTEM_USER_URL = BASE_API_URL + "user/addSystemUser";
export const HARD_CODE_ROLE_LIST = BASE_API_URL + "master/roleList";
export const USER_SYS_DELETE_URL = BASE_API_URL + "user/deleteSystemUser";

export const AZURE_DOMAIN_LIST_URL = BASE_API_URL + "microsoftApi/domainList";

// organization details used
export const LICENSE_ADD_FOR_ORGANIZATION =
  BASE_API_URL + "licenses/addNewLicense";
export const HIDE_LICENSE_URL = BASE_API_URL + "licenses/hideLicense";
export const DELETE_LICENSE_URL = BASE_API_URL + "licenses/deleteLicense";
export const ADD_OFFICE_URL = BASE_API_URL + "offices/addOffice";
export const DELETE_OFFICES_URL = BASE_API_URL + "offices/deleteOffice";
export const ADD_DEPARTMENT_URL = BASE_API_URL + "departments/addDepartment";
export const DELETE_DEPARTMENT_URL =
  BASE_API_URL + "departments/deleteDepartment";
export const ADD_ROLE_URL = BASE_API_URL + "roles/addRole";
export const DELETE_ROLE_URL = BASE_API_URL + "roles/deleteRoles";
export const JOB_TITLE_URL_ADD = BASE_API_URL + "jobTitles/addJobTitle";
export const DELETE_JOB_TITLE_URL = BASE_API_URL + "jobTitles/deleteJobTitle";

export const USERS_LIST_FROM_AZURE_FOR_USERS_URL =
  BASE_API_URL + "user/employeeUserList";
export const USERS_DISABLE_WITH_FORWARD_URL =
  BASE_API_URL + "user/disableUserWithForwarding";
export const ALL_USERS_LIST_URL = BASE_API_URL + "user/allUsersList";
export const USERS_ENABLE_WITH_REMOVE_FORWARD_URL =
  BASE_API_URL + "user/enableUserWithRemoveForwarding";
export const APPROVE_STATUS_CHANGE_URL =
  BASE_API_URL + "requestLicense/approveRequest";

export const ADD_ORGANIZATION_DETAILS_URL =
  BASE_API_URL + "organization/addUserOrganizationDetails";
export const EDIT_ACCOUNTDETAILS_URL = BASE_API_URL + "user/editProfile";

export const WORK_FLOW_LIST_URL = BASE_API_URL + "workflow/workflowList";
export const ADD_WORKFLOW_URL = BASE_API_URL + "workflow/addWorkflow";
export const DELETE_WORKFLOW_URL = BASE_API_URL + "workflow/deleteWorkflow";
export const EDIT_WORKFLOW_URL = BASE_API_URL + "workflow/editWorkflow";
export const GET_WORKFLOW_DETAILS_URL = BASE_API_URL + "workflow/getDetails";

export const REPORT_LISTED_URL = BASE_API_URL + "reports/usersReportList";
export const REQUESTER_LICENSE_REQUEST_LIST_URL =
  BASE_API_URL + "requestLicense/requesterLicenseList";
export const SIGN_UP_URL = BASE_API_URL + "microsoftApi/signUp";
export const SUB_DOMAIN_VALID_URL =
  BASE_API_URL + "microsoftApi/checkSubDomain";
export const DELETE_ACTIVITY_LOG_URL =
  BASE_API_URL + "activitylog/deleteActivitylog";

export const GET_NOTIFICATIONS_SETTINGS_URL =
  BASE_API_URL + "notification/settingsDetails";
export const SET_NOTIFICATIONS_SETTINGS_URL =
  BASE_API_URL + "notification/addSettings";
export const APPROVE_FROM_NOTIFICATION =
  BASE_API_URL + "requestLicense/ApproveViaTeams";
export const REJECT_FROM_NOTIFICATION =
  BASE_API_URL + "requestLicense/RejectViaTeams";

export const ADD_USER_TO_GROUP_MEMBER = BASE_API_URL + "group/addGroupMember";
export const DELETE_MEMBER_FROM_GROUP =
  BASE_API_URL + "group/deleteGroupMember";
export const DOMAIN_BY_EMAIL_ID_URL =
  BASE_API_URL + "microsoftApi/subDomainByEmail";
export const WORKFLOW_USERS_LIST_URL =
  BASE_API_URL + "workflow/workflowUserList";
export const ALL_LICENSE_REQUEST_LIST_URL =
  BASE_API_URL + "requestLicense/allLicenseRequestList";
export const ALL_REQUESTER_APPROVAL_LIST_URL =
  BASE_API_URL + "requestLicense/allLicenseRequestListForRequester";

// mailbox
export const MAILBOX_LIST_URL = BASE_API_URL + "mailbox/mailboxList";
export const ADD_CONFIG_MAIL_BOX_URL = BASE_API_URL + "mailbox/addConfigure";
export const GET_CONFIG_MAIL_BOX_URL = BASE_API_URL + "mailbox/getConfigure";
export const SHARED_MAIL_BOX_LIST_URL =
  BASE_API_URL + "mailbox/sharedMailboxList";
export const CONVERT_SHARED_MAIL_URL = BASE_API_URL + "mailbox/convertToShared";
export const EXPORT_THE_REPORT_URL =
  BASE_API_URL + "reports/exportUsersReportList";
export const DELETE_THE_EXPORT_URL = BASE_API_URL + "reports/deleteReport";
export const MAILBOX_USER_LIST_URL = BASE_API_URL + "mailbox/mailboxes";
export const CODE_AUTH_FOR_USER_URL =
  BASE_API_URL + "microsoftApi/getDelegatedToken";
export const PERMISSION_URL =
  BASE_API_URL + "microsoftApi/configurePermissions";
export const PERMISSIONN_FLAG_UPDATE_URL =
  BASE_API_URL + "microsoftApi/updatePermissionFlag";
export const LICENSE_LIST_FROM_PAX = BASE_API_URL + "pax8Api/pax8LicenseList";

export const REQUEST_LIST_FOR_ADMIN =
  BASE_API_URL + "requestLicense/allLicenseRequestListForAdmin";
export const DELETE_LICENSE_REQUEST =
  BASE_API_URL + "requestLicense/deleteRequest";
export const REQUESTER_ACC_USER_LIST = BASE_API_URL + "user/requesterUsersList";
export const APPROVER_ACC_USER_LIST = BASE_API_URL + "user/approverUsersList";
export const CHK_USER_NAME_EXIST =
  BASE_API_URL + "requestLicense/checkUserNameExist";
export const DOMAIN_LIST_FROM_SETTINGS =
  BASE_API_URL + "organization/organizationDomainList";
export const ADD_DOMAIN_LIST =
  BASE_API_URL + "organization/addOrganizationDomain";
export const DELETE_DOMAIN_LIST =
  BASE_API_URL + "organization/deleteOrganizationDomain";
export const DEVICE_LIST_URL =
  BASE_API_URL + "intuneReport/deviceComplianceReport";
export const SETTING_DEVICE_VISIBILITY_URL =
  BASE_API_URL + "intuneReport/updateIntuneSettings";
export const WIPE_THE_DEVICE_URL = BASE_API_URL + "intuneReport/wipeDevice";
export const RESTART_THE_DEVICE_URL =
  BASE_API_URL + "intuneReport/rebootDevice";
export const DEVICE_NEW_PERMISSION_URL =
  BASE_API_URL + "microsoftApi/updateADPermissions";
export const FIRST_ALERT_FLAG_URL =
  BASE_API_URL + "microsoftApi/changeNewUpdateFlag";
export const DEPARTMENT_IMPORT_URL =
  BASE_API_URL + "departments/importDepartments";
export const OFFICES_IMPORT_URL = BASE_API_URL + "offices/importOffices";
export const JOB_TITLES_IMPORT_URL = BASE_API_URL + "jobTitles/importJobTitles";
export const WORKFLOW_FLAG_CHANGE_URL = BASE_API_URL + "workflow/changeWorkflowFlag";
export const BOT_PUBLISH_URL=BASE_API_URL+"microsoftApi/publishApp"
export const BOT_OBJECTID_STORE=BASE_API_URL+"microsoftApi/updateADPermissionsWithoutObjectId"