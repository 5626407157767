import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import backicon from "../../images/backicon.png";
import Logo from "../../images/woodgrove.png";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { ORGANIZATION_SETTING_ROUTE } from "../../constants/RoutePath";
import {
  ADD_ORGANIZATION_DETAILS_URL,
  EDIT_ORGANIZATION_DETAILS,
  FILE_UPLOAD_URL,
  GET_ORGANIZATION_DETAILS,
} from "../common/ApiUrl";
import API from "../common/API";
import {
  CustomMessage,
  authHeader,
  getDomain,
  getSideFlag,
} from "../common/mainfunctions";
import { useSnackbar } from "notistack";
import CustomLoader from "../common/CustomLoader";
import nameblue from "../../images/nameblue.png";
import maili from "../../images/maili.png";
import callblue from "../../images/call blue.png";
import locationblue from "../../images/location blue.png";
import { useDispatch, useSelector } from "react-redux";
import { getStateList, getZipCodeList } from "../../redux/action";
import editIcon from "../../images/editIcon.png";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import texprofile from "../../images/textup.jpg";
export default function EditOrganizationDetails() {
  const sl = useSelector((sl) => sl.StateListReducer);
  const zl = useSelector((zl) => zl.ZipcodeReducer);
  const navigation = useNavigate();
  const tok = authHeader();
  let domain = getDomain();

  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const oid = location.state.eid;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    contactEmail: Yup.string()
      .email("Email address is invalid.")
      .required("Required"),
  });
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      contactEmail: "",
      contactNumber: "",
      address: "",
      state: "",
      zipCode: "",
      profilePath: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      editDetails(values);
    },
  });
  const getOrgDetails = async (values) => {
    const config = {
      method: "GET",
      url: GET_ORGANIZATION_DETAILS,
      headers: authHeader(),
      params: {
        organizationId: oid,
      },
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        const { orgDetails = [] } = data;
        const {
          name,
          contactEmail,
          contactNumber,
          address,
          state,
          zipCode,
          profilePath,
        } = orgDetails;
        setValues({
          name: name,
          contactEmail: contactEmail,
          contactNumber: contactNumber,
          address: address,
          state: state,
          zipCode: zipCode,
          profilePath: profilePath == null ? "null" : profilePath,
        });
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
    }
  };
  useEffect(() => {
    if (oid) {
      getOrgDetails();
    }
  }, [oid]);

  useEffect(() => {
    dispatch(getStateList());
    dispatch(getZipCodeList());
  }, []);
  const editDetails = async (values) => {
    const {
      name,
      contactEmail,
      contactNumber,
      address,
      state,
      zipCode,
      profilePath,
    } = values;
    setLoading(true);
    const config = {
      method: oid ? "PUT" : "POST",
      url: oid ? EDIT_ORGANIZATION_DETAILS : ADD_ORGANIZATION_DETAILS_URL,
      headers: authHeader(),
      data: oid
        ? {
            organizationId: parseInt(oid),
            name: name,
            contactEmail: contactEmail,
            contactNumber: contactNumber,
            address: address,
            state: state,
            zipCode: zipCode,
            profilePath: profilePath,
          }
        : {
            name: name,
            contactEmail: contactEmail,
            contactNumber: contactNumber,
            address: address,
            state: state,
            zipCode: zipCode,
            profilePath: profilePath,
          },
    };
    try {
      const response = await API(config);
      const { status, data, message } = response.data;
      if (status == "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }
  };

  const cancelFun = () => {
    navigation("/organization");
  };
  useEffect(() => {
    if (!tok) {
      navigation("/signin");
    } else {
      console.log("tok", `*****${tok}`);
    }
  }, [tok]);
  const handleChangeState = (e) => {
    setValues({ ...values, state: e.target.value });
  };
  const handleChangeZip = (e) => {
    setValues({ ...values, zipCode: e.target.value });
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const options = {
      method: "POST",
      url: FILE_UPLOAD_URL,
      headers: authHeader(),
      data: formData,
    };
    try {
      var apiResponse = await API(options);
      const { status, data } = apiResponse.data;
      if (status === "Success") {
        setValues({ ...values, profilePath: data });
      }
    } catch (error) {
      if (error.response) {
        CustomMessage(error.response, "error", enqueueSnackbar);
      }
    }
  };
  const handleOnChange = (value) => {
    setValues({ ...values, contactNumber: value });
  };
  return (
    <Box sx={{ display: "flex" }} component="form" onSubmit={handleSubmit}>
      {getSideFlag() == "true" ? (
        <></>
      ) : (
        <Navbar name="Organization Settings" />
      )}

      {/* <Sidebar /> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: { xs: "0px", md: "3" },
          mt: getSideFlag() == "true" ? "-11px" : "65px",
        }}
      >
        <Stack direction="row" spacing={2} className="tit-le">
          <IconButton>
            <img src={backicon} onClick={cancelFun} />
          </IconButton>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "17px",
              lineHeight: "26px",
              marginTop: "2px",
            }}
          >
            {oid ? "Edit Organization" : "Create Organization"}
          </Typography>
        </Stack>
        <Box
          style={{
            cursor: "pointer",
            backgroundColor: "#FFFFFF",
            marginTop: "10px",
          }}
        >
          <Stack direction="row" justifyContent="center">
            <Box
              className="manageUpdate"
              style={{ margin: "15px", position: "relative" }}
            >
              <img
                className={
                  values.profilePath != "null" ? "uploadImg" : "uploadImg1"
                }
                src={
                  values.profilePath != "null" ? values.profilePath : texprofile
                }
              />

              <Tooltip title="Click here to upload organization logo" arrow>
                <label
                  style={{ marginBottom: "0", cursor: "pointer" }}
                  className="btn-bs-file btn btn-lg btn-danger admin-pro-camera"
                >
                  <img src={editIcon} />
                  <Input
                    accept=".png,.jpg,.jpeg"
                    type="file"
                    onChange={handleUpload}
                    style={{ display: "none" }}
                  />{" "}
                </label>
              </Tooltip>
            </Box>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={3}
            justifyContent="space-around"
          >
            <Input
              className="loginfields"
              style={{ margin: "15px" }}
              endAdornment={
                <InputAdornment position="end">
                  <img src={nameblue} />
                </InputAdornment>
              }
              //   // required
              id="name"
              name="name"
              placeholder="Organization name"
              value={values.name}
              onChange={handleChange}
              error={errors.name && touched.name}
              helperText={errors.name && touched.name ? errors.name : ""}
            />
            <Input
              className="loginfields"
              style={{ margin: "15px" }}
              endAdornment={
                <InputAdornment position="end">
                  <img src={locationblue} />
                </InputAdornment>
              }
              //   // required
              id="address"
              name="address"
              placeholder="Address"
              value={values.address}
              onChange={handleChange}
              error={errors.address && touched.address}
              helperText={
                errors.address && touched.address ? errors.address : ""
              }
            />
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={3}
            justifyContent="space-around"
          >
            <FormControl style={{ width: "100%", padding: "0px 26px 0px 0px" }}>
              <Input
                className="loginfields"
                style={{ margin: "15px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <img src={maili} />
                  </InputAdornment>
                }
                //   // required
                id="contactEmail"
                name="contactEmail"
                placeholder="Email"
                value={values.contactEmail}
                onChange={handleChange}
                error={errors.contactEmail && touched.contactEmail}
                helperText={
                  errors.contactEmail && touched.contactEmail
                    ? errors.contactEmail
                    : ""
                }
              />
              <FormHelperText error={true}>
                {errors.contactEmail && touched.contactEmail
                  ? errors.contactEmail
                  : ""}
              </FormHelperText>
            </FormControl>
            {/* <Input
                  className="loginfields"
                  style={{margin:'15px'}}
                
                  id="state"
                  name="state"
                  placeholder="Enter State"
                  value={values.state}
                  onChange={handleChange}
                  error={errors.state && touched.state}
                  helperText={errors.state && touched.state ? errors.state : ""}
                /> */}
            <FormControl
              style={{
                marginLeft: "15px",
                width: "-webkit-fill-available",
                marginRight: "18px",
                marginTop: "10px",
              }}
            >
              {/* <InputLabel id="demo-simple-select-label">State</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.state}
                // label="State"
                onChange={handleChangeState}
                renderValue={(selected) => {
                  if (!selected) {
                    return "Select State";
                  }
                  return selected;
                }}
              >
                <MenuItem value="" disabled>
                  Select State
                </MenuItem>
                {sl &&
                  sl.map((rec, i) => {
                    return (
                      <MenuItem key={rec.id} value={rec.stateName}>
                        {rec.stateName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={5}
            justifyContent="space-around"
          >
            {/* <Input
              className="loginfields"
              style={{ margin: "15px" }}
              endAdornment={
                <InputAdornment position="end">
                  <img src={callblue} />
                </InputAdornment>
              }
              //   // required
              id="contactNumber"
              name="contactNumber"
              placeholder="Enter Phone number"
              value={values.contactNumber}
              onChange={handleChange}
              error={errors.contactNumber && touched.contactNumber}
              helperText={
                errors.contactNumber && touched.contactNumber
                  ? errors.contactNumber
                  : ""
              }
            /> */}
            <PhoneInput
              country={"us"}
              onChange={handleOnChange}
              value={values.contactNumber}
            />
            <FormControl
              style={{
                marginLeft: "8px",
                width: "-webkit-fill-available",
                marginRight: "18px",
                marginBottom: "10px",
              }}
              className="logofields"
            >
              {/* <InputLabel id="demo-simple-select-label">Zip Code</InputLabel> */}
              <Input
                className="loginfields"
                labelId="zipCode"
                id="zipCode"
                value={values.zipCode}
                placeholder="Zip Code"
                onChange={handleChangeZip}
              ></Input>
            </FormControl>
          </Stack>
        </Box>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 2.5, sm: 2.5, md: 5 }}
          justifyContent="space-around"
          sx={{ margin: { xs: "15px 0px", sm: "15px 0px", md: "15px" } }}
        >
          <Button
            sx={{
              backgroundColor: "#F65314",
              color: "#FFFFFF",
              width: { xs: "100%", sm: "100%", md: "465px" },
              height: "50px",
              borderRadius: "5px",
            }}
            onClick={cancelFun}
          >
            CANCEL
          </Button>
          <Button
            sx={{
              color: "#FFFFFF",
              backgroundColor: "#00A1F1",
              width: { xs: "100%", sm: "100%", md: "465px" },
              height: "50px",
              borderRadius: "5px",
            }}
            type="submit"
            onClick={handleSubmit}
          >
            {loading && <CustomLoader loading={loading ? true : false} />}
            SAVE CHANGES
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
