import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Box,
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormik } from "formik";
import {
  ADD_WORKFLOW_URL,
  ALL_USERS_LIST_URL,
  EDIT_WORKFLOW_URL,
  GET_DISTRIBUTION_GROUP_LIST_URL,
  GET_WORKFLOW_DETAILS_URL,
  WORKFLOW_USERS_LIST_URL,
} from "../common/ApiUrl";
import { CustomMessage, authHeader } from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import flowIcon from "../../images/workflow.png";
import { useState } from "react";
import CustomLoader from "../common/CustomLoader";
import * as Yup from "yup";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInputSel = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const initialValues = {
  requesterType: "User",
  requesterName: "",
  approverType: "User",
  approverName: "",
  requesterId: "",
  approverId: "",
};
export default function AddNewWorkFlowPopup(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [userList, setUserList] = useState();
  const [groupList, setGroupList] = useState();
  const [requesterList, setRequesterList] = useState();
  const { open, handleClose, gid, orgid, flag, id, successcall,details } = props;
  const [loading, setLoading] = useState(false);
  const [reqList, setReqList] = useState();
  const [aprList, setAprList] = useState();
  const [aproverList, setAproverList] = useState();
  const validationSchema = Yup.object({
    requesterType: Yup.string().required("Requester type is required"),
    requesterId: Yup.string().required("Requester is required"),
    approverType: Yup.string().required("Approver type is required"),
    approverId: Yup.string().required("Approver is required"),
  });
  const addNewWorkFlowFun = async (values) => {
    const { requesterType, requesterId, approverType, approverId } = values;
    if (
      requesterType == "" ||
      requesterId == "" ||
      approverType == "" ||
      approverId == ""
    ) {
      CustomMessage(
        "Missing required parameters. Please provide all necessary information.",
        "error",
        enqueueSnackbar
      );
    } else {
      setLoading(true);
      let apremail = "";
      let rqremail;
      if (requesterType == "User") {
        rqremail = reqList.filter((r) => r.id == requesterId)[0].email;
      } else {
        rqremail = reqList.filter((r) => r.id == requesterId)[0].mail;
      }
      if (approverType == "User") {
        apremail = aprList.filter((r) => r.id == approverId)[0].email;
      } else {
        apremail = aprList.filter((r) => r.id == approverId)[0].email;
      }
      let aprname = aprList.filter((r) => r.id == approverId)[0].displayName;
      let rqrname = reqList.filter((r) => r.id == requesterId)[0].displayName;

      const config = {
        method: id ? "PUT" : "POST",
        url: id ? EDIT_WORKFLOW_URL : ADD_WORKFLOW_URL,
        headers: authHeader(),
        data: id
          ? {
              workflowId: parseInt(id),
              requesterType: requesterType,
              requesterName: rqrname,
              requesterId: requesterId,
              approverType: approverType,
              approverName: aprname,
              approverId: approverId,
              requesterEmail: rqremail,
              approverEmail: apremail,
            }
          : {
              organizationId: parseInt(orgid),
              requesterType: requesterType,
              requesterName: rqrname,
              requesterId: requesterId.toString(),
              approverType: approverType,
              approverName: aprname,
              approverId: approverId.toString(),
              requesterEmail: rqremail,
              approverEmail: apremail,
            },
      };
      try {
        const response = await API(config);
        const { status, message } = response.data;
        if (status == "Success") {
          CustomMessage(message, "success", enqueueSnackbar);
          successcall();
        }
      } catch (error) {
        console.log(error);
        if (error) {
          const { data } = error.response;
          const { message, status } = data;
          CustomMessage(message, status, enqueueSnackbar);
        }
      } finally {
        setLoading(false);
      }
    }
  };
  const getAllUsersList = async (search) => {
    const config = {
      method: "GET",
      url: ALL_USERS_LIST_URL,
      headers: authHeader(),
      params: {
        search: "",
      },
    };
    try {
      const response = await API(config);
      const { message, status, data } = response.data;
      if (status == "Success") {
        setUserList(data.value);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllgroupList = async (search) => {
    const config = {
      method: "GET",
      url: GET_DISTRIBUTION_GROUP_LIST_URL,
      headers: authHeader(),
      params: {
        search: "",
      },
    };
    try {
      const response = await API(config);
      const { message, status, data } = response.data;
      if (status == "Success") {
        setGroupList(data.value);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllUsersList();
    getAllgroupList();
  }, [id]);
  const { handleSubmit, handleChange, setValues, values, touched, errors } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      // validationSchema:validationSchema,
      onSubmit: (values) => {
        addNewWorkFlowFun(values);
      },
    });
  const getWorkflowDetails = async () => {
    const config = {
      method: "GET",
      url: GET_WORKFLOW_DETAILS_URL,
      headers: authHeader(),
      params: {
        workflowId: id,
      },
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        const { details } = data;
        const {
          requesterType,
          approverName,
          approverType,
          requesterName,
          requesterId,
          approverId,
          approverEmail,
          requesterEmail,
        } = details;
        setValues({
          requesterType: requesterType,
          approverName: approverName,
          requesterName: requesterName,
          approverType: approverType,
          requesterId: requesterId,
          approverId: approverId,
          requesterEmail: requesterEmail,
          approverEmail: approverEmail,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getWorkflowrequesterDetails = async () => {
    const config = {
      method: "GET",
      url: WORKFLOW_USERS_LIST_URL,
      headers: authHeader(),
      params: {
        type: "requester",
      },
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        setRequesterList(data.list);
        setReqList(data.list)
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getWorkflowapproverDetails = async () => {
    const config = {
      method: "GET",
      url: WORKFLOW_USERS_LIST_URL,
      headers: authHeader(),
      params: {
        type: "approver",
      },
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        setAproverList(data.list);
        setAprList(data.list)
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getWorkflowrequesterDetails();
    getWorkflowapproverDetails();
  }, []);
  useEffect(() => {
    console.log("id", id);
    if (requesterList && aproverList && id&&details.length>0) {
      // getWorkflowDetails();
 const {
   requesterType,
   approverName,
   approverType,
   requesterName,
   requesterId,
   approverId,
   approverEmail,
   requesterEmail,
 } = details;
 setValues({
   requesterType: requesterType,
   approverName: approverName,
   requesterName: requesterName,
   approverType: approverType,
   requesterId: requesterId,
   approverId: approverId,
   requesterEmail: requesterEmail,
   approverEmail: approverEmail,
 });
    }
  }, [id, requesterList, aproverList]);
    useEffect(() => {
      console.log("id", id,details);
      if (id) {
        getWorkflowDetails();
         const {
           requesterType,
           approverName,
           approverType,
           requesterName,
           requesterId,
           approverId,
           approverEmail,
           requesterEmail,
         } = details;
         setValues({
           requesterType: requesterType,
           approverName: approverName,
           requesterName: requesterName,
           approverType: approverType,
           requesterId: requesterId,
           approverId: approverId,
           requesterEmail: requesterEmail,
           approverEmail: approverEmail,
         });

      }
    }, [id, reqList, aprList]);
  const handleChangeReqType = (e) => {
    handleChange(e);
    const type = e.target.value;
    console.log("type", type);
    if (type == "User") {
      setReqList(requesterList);
    } else {
      setReqList(groupList);
    }
  };
  const handleChangeAprType = (e) => {
    handleChange(e);
    const type = e.target.value;
    if (type == "User") {
      setAprList(aproverList);
    } else {
      setAprList(groupList);
    }
  };
  
  return (
    <div>
      <BootstrapDialog
        className="org-popup"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box>
            <Box style={{ textAlign: "center" }}>
              <img src={flowIcon} />
            </Box>
            <Box style={{ textAlign: "center" }}>
              <Typography
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  alignContent: "center",
                }}
              >
                {id ? "Edit Workflow" : "Add New Workflow"}
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                Requester Type
              </InputLabel>

              <FormControl
                style={{ marginTop: "10px" }}
                sx={{ width: "100%", minWidth: "500px" }}
                variant="standard"
              >
                <BootstrapInput
                  labelId="requesterType"
                  id="requesterType"
                  value={values.requesterType}
                  name="requesterType"
                  placeholder="Requester Type"
                  // onChange={handleChangeReqType}
                >
                  {/* <MenuItem selected={true} disabled={true} value="Domain">Domain</MenuItem> */}
                  {/* {["User"].map((rec, i) => {
                    return (
                      <MenuItem key={i} name={rec} value={rec}>
                        {rec}
                      </MenuItem>
                    );
                  })} */}
                </BootstrapInput>
                <FormHelperText error={true}>
                  {errors.requesterType && touched.requesterType
                    ? errors.requesterType
                    : ""}
                </FormHelperText>
              </FormControl>
              <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                Requester{" "}
              </InputLabel>

              <FormControl
                style={{ marginTop: "10px" }}
                sx={{ width: "100%", minWidth: "500px" }}
                variant="standard"
              >
                <BootstrapInputSel
                  labelId="requesterId"
                  id="requesterId"
                  value={values.requesterId}
                  name="requesterId"
                  placeholder="Requester Name"
                  onChange={handleChange}
                >
                  {reqList &&
                    reqList.map((rec, i) => {
                      return (
                        <MenuItem key={rec.id} name={rec.id} value={rec.id}>
                          {rec.displayName}
                        </MenuItem>
                      );
                    })}
                </BootstrapInputSel>
                <FormHelperText error={true}>
                  {errors.requesterId && touched.requesterId
                    ? errors.requesterId
                    : ""}
                </FormHelperText>
              </FormControl>
              <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                Approver Type{" "}
              </InputLabel>

              <FormControl
                style={{ marginTop: "10px" }}
                sx={{ width: "100%", minWidth: "500px" }}
                variant="standard"
              >
                <BootstrapInput
                  labelId="approverType"
                  id="approverType"
                  value={values.approverType}
                  name="approverType"
                  placeholder="Approver Type"
                  // onChange={handleChangeAprType}
                >
                
                </BootstrapInput>
                <FormHelperText error={true}>
                  {errors.approverType && touched.approverType
                    ? errors.approverType
                    : ""}
                </FormHelperText>
              </FormControl>
              <InputLabel
                htmlFor="my-input"
                sx={{
                  textAlign: "left",
                  fontWeight: "700",
                  fontSize: "16px",
                  mt: "10px",
                }}
              >
                Approver{" "}
              </InputLabel>

              <FormControl
                style={{ marginTop: "10px" }}
                sx={{ width: "100%", minWidth: "500px" }}
                variant="standard"
              >
                <BootstrapInputSel
                  labelId="approverId"
                  id="approverId"
                  value={values.approverId}
                  name="approverId"
                  placeholder="Approver Name"
                  onChange={handleChange}
                >
                  {aprList &&
                    aprList.map((rec, i) => {
                      return (
                        <MenuItem key={i} name={rec.id} value={rec.id}>
                          {rec.displayName}
                        </MenuItem>
                      );
                    })}
                </BootstrapInputSel>
                <FormHelperText error={true}>
                  {errors.approverId && touched.approverId
                    ? errors.approverId
                    : ""}
                </FormHelperText>
              </FormControl>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  className="login-btn-group-bl"
                  size="medium"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  <CustomLoader loading={loading} />
                  SUBMIT
                </Button>
                <Button
                  className="login-btn-group"
                  size="medium"
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
