import React, { Fragment } from 'react'
import loaderComponent from '../../images/loader.gif'
export default function CustomLoaderSmall({ loading }) {
  return (
    <Fragment>
      {loading && (
        <img
          alt="loading"
          src={loaderComponent}
          style={{ width: '18px', height: '18px',marginRight:'5px' }}
        />
      )}
    </Fragment>
  )
}
