import React, { useEffect, useState } from "react";
import {
  DEVICE_NEW_PERMISSION_URL,
  FIRST_ALERT_FLAG_URL,
  PERMISSIONN_FLAG_UPDATE_URL,
  PERMISSION_URL,
} from "../../common/ApiUrl";
import { CustomMessage, authHeader } from "../../common/mainfunctions";
import API from "../../common/API";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  Typography,
  alpha,
} from "@mui/material";
import BitMapPax from "../../../images/Bitmap.png";
import CustomLoader from "../../common/CustomLoader";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import entraid from "../../../images/menid.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../../redux/action";
import BotAppPermission from "./BotAppPermission";
import { localStorageAvailable } from "@mui/x-data-grid/utils/utils";
import CustomLoaderSmall from "../../common/CustomLoaderSmall";
import * as microsoftTeams from "@microsoft/teams-js";
import { useLocation } from "react-router";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(10),
  },
  "& .MuiInputBase-input": {
    borderRadius: "0px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    marginTop: "15px !important",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const initialValues = {
  appObjectId: "",
};
export default function PermissionAlert(props) {
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;
  let cliendid = process.env.REACT_APP_CLIENT_ID;

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { open, handleClose } = props;
  const [code,setCode]=useState()
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    // appObjectId: Yup.string().trim().required("Object Id is required"),
  });
  const location = useLocation();

  const [openAppIdFlag,setAppIdFlag]=useState(false)
  const [mobFlag, setMobFlag] = useState(false);
  const [sideFlag, setSideFlag] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const tokens = queryParams.get("tokens");
  const userDet = useSelector((ud) => ud.UserDetailsReducer);

  const {
    msClientId="",
    permissionUpdatedFlag=3
  } = userDet || {};

  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (
      /android|iPhone|iPad|iPod/i.test(userAgent) ||
      window.location.href.includes("teams.microsoft.com")
    );
  };
  useEffect(() => {
    if (isMobile()) {
      setMobFlag(true);
      // CustomMessage("get from mobile permissionalert", "success", enqueueSnackbar);

      console.log("Running inside Microsoft Teams on Mobile");
      // Handle mobile-specific logic
    }
  }, []);
 

  useEffect(() => {
    // Check if the app is running inside Microsoft Teams
    if (window.location !== window.parent.location) {
      setSideFlag(true);
      localStorage.setItem("SIDE_FLAG", true);
      microsoftTeams.initialize();
    } else {
      console.log("App is running outside of Microsoft Teams");
      localStorage.setItem("SIDE_FLAG", false);
      setSideFlag(false);
    }
  }, []);
  const isInTeams = () => {
    return window.self !== window.top;
  };
  const hclose=()=>{
    setLoading(false)
    setAppIdFlag(false)
    handleClose()
  }
  
  const handleFirstTok=async()=>{
    if (isInTeams()) {
      window.open(
        `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                      response_type=id_token%20code
                      &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send%20Application.Read.All
                      &client_id=${cliendid}
                      &redirect_uri=${redirecturl}
                      &state=12345
                      &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
                      &client_info=1
                      &x-client-SKU=MSAL.JS
                      &x-client-Ver=1.4.18
                      &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
                      &response_mode=fragment`,"_blank"
      );
      // Initialize Microsoft Teams SDK only if inside Teams
      // microsoftTeams.initialize((initErr) => {
      //   if (initErr) {
      //     console.error("Teams initialization error:", initErr);
      //     return;
      //   }
      //   console.log("Teams SDK initialized");

      //   // Now use Teams authentication flow
      //   microsoftTeams.authentication.authenticate({
      //     url: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
      //                   response_type=id_token%20code
      //                   &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send%20Application.Read.All
      //                   &client_id=${cliendid}
      //                   &redirect_uri=${redirecturl}
      //                   &state=12345
      //                   &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
      //                   &client_info=1
      //                   &x-client-SKU=MSAL.JS
      //                   &x-client-Ver=1.4.18
      //                   &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
      //                   &response_mode=fragment`,
      //     width: 500,
      //     height: 600,
      //     successCallback: (result) => {
      //       console.log("Authentication successful:", result);
      //       // Handle the result (e.g., token)
      //     },
      //     failureCallback: (reason) => {
      //       console.error("Authentication failed:", reason);
      //     },
      //   });
      
      // });
    } else if (mobFlag === true&&permissionUpdatedFlag==0) {
      // Mobile flow: redirect-based authentication
      // CustomMessage("get from mobile flag admin", "success", enqueueSnackbar);

      window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                        response_type=id_token%20code
                        &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send%20Application.Read.All
                        &client_id=${cliendid}
                        &redirect_uri=${redirecturl}
                        &state=12345
                        &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
                        &client_info=1
                        &x-client-SKU=MSAL.JS
                        &x-client-Ver=1.4.18
                        &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
                        &response_mode=fragment`;
    } else {
  
    const popup = window.open("", "_blank", "width=500,height=600");
    window.addEventListener("message", (event) => {
     
    });
    popup.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                        response_type=id_token%20code
                        &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send%20Application.Read.All
                        &client_id=${cliendid}
                        &redirect_uri=${redirecturl}
                        &state=12345
                        &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
                        &client_info=1
                        &x-client-SKU=MSAL.JS
                        &x-client-Ver=1.4.18
                        &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
                        &response_mode=fragment`;
  }
}
useEffect(() => {
  // Check if the app is running inside Microsoft Teams
  if (window.location !== window.parent.location) {
    setSideFlag(true);
    localStorage.setItem("SIDE_FLAG", true);
    microsoftTeams.initialize();
  } else {
    console.log("App is running outside of Microsoft Teams");
    localStorage.setItem("SIDE_FLAG", false);
    setSideFlag(false);
  }
}, []);
  const handleCloseBef = async () => {
    handleFirstTok()
  };
 
  useEffect(() => {
    const handleMessage = (event) => {
      const { data } = event;
  
      // Show a custom message for debugging
      // CustomMessage("Message received", "success", enqueueSnackbar);
  
      if (data && data.type === "tokenMessage") {
        console.log("Token Message Data:", data);
  
        // Extract and decode the token
        const receivedToken = data.token.split("::")[0];
        const base64Url = receivedToken.split(".")[1];
        const cd = data.token.split("::")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const decodedData = JSON.parse(atob(base64));
        const userDisplayName = decodedData.preferred_username;
        const tid = decodedData.tid;
  
        // Store token/code and update state
        localStorage.setItem("CODE", cd);
        setCode(cd);
        if(permissionUpdatedFlag==0){
        setAppIdFlag(true);
        }
        setLoading(true);
  
        // Acknowledge message received
        event.source.postMessage({ type: "tokenReceived" }, event.origin);
      }
  
      if (data && data.type === "code") {
        console.log("Code received:", data);
        // Handle any additional code logic if needed
      }
    };
  
    // Add message listener
    window.addEventListener("message", handleMessage);
  
    return () => {
      // Clean up listener on unmount
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  useEffect(() => {
    if ((mobFlag == true || isMobile() == true) && tokens) {

      // Extract and decode the token
      const receivedToken = tokens.split("::")[0];
      const base64Url = receivedToken.split(".")[1];
      const cd = tokens.split("::")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const decodedData = JSON.parse(atob(base64));
      const userDisplayName = decodedData.preferred_username;
      const tid = decodedData.tid;

      // Store token/code and update state
      localStorage.setItem("CODE", cd);
      setCode(cd);
      // CustomMessage(`get success code${cd}`, "success", enqueueSnackbar);
      if(permissionUpdatedFlag==0){

      setAppIdFlag(true);
      }
      setLoading(true);

      // CustomMessage("get success in return", "success", enqueueSnackbar);

    }
  }, [tokens]);
 
  console.log('code',code)
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // permissionFun(values);
    },
  });

  const closeFun = () => {};
  return (
    <>
      <div className="mar-top-azure">
        <BootstrapDialog
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          disableBackdropClick
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></BootstrapDialogTitle>
          <DialogContent className="mar-top-azure">
            <Box style={{ textAlign: "center" }}>
              <img style={{ height: "50px", width: "auto" }} src={entraid} />
            </Box>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
               Permissions Update Required
            </Typography>
            <form>
              <Typography
                style={{
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >
               
               To continue using this app, please enable the newly added permissions. 
               This ensures access to the latest features and functionality.
              </Typography>

              <div style={{ textAlign: "right" }}>
                <Button className="login-btn-apr" 
                style={{width:'auto'}}
                onClick={handleCloseBef}>
                  {loading&&<CustomLoaderSmall loading={loading}/>}
                  Proceed
                </Button>
              </div>
            </form>
          </DialogContent>
        </BootstrapDialog>
      </div>
      {openAppIdFlag&&<BotAppPermission open={openAppIdFlag} handleClose={hclose} code={code} appPer={true} botFlag={false}/>}
    </>
  );
}